"use client";
import React from "react";
import {cn} from "@/lib/utils";

interface SpaceDivProps {
    bg?: string;
    tips?: string;
}

export function SpaceDiv({bg, tips}: SpaceDivProps) {
    return (
        <div className={cn("border-y " , bg ? `border-y bg-[${bg}] border-foreground dark:border-background/70` : 'border-y')}>
            <div className={cn("container text-foreground dark:text-secondary flex text-xl font-medium justify-center items-center border-x h-20 " , bg ? `border-foreground dark:border-background/70` : 'border-0')}>
                {tips}
            </div>
        </div>
    );
}