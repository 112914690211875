"use client"
import Link from "next/link"

import {cn} from "@/lib/utils"
import {CommandMenu} from "@/components/command-menu"
import {Icons} from "@/components/icons"
import {useTranslations} from 'next-intl';
import {ModeToggle} from "@/components/mode-toggle"
import {Button, buttonVariants} from "@/registry/new-york/ui/button"
import {useSession} from "next-auth/react";
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator, DropdownMenuShortcut,
    DropdownMenuTrigger
} from "@/registry/new-york/ui/dropdown-menu";
import {Avatar, AvatarFallback, AvatarImage} from "@/registry/new-york/ui/avatar";
import {useUser} from "@/features/account/hooks/useUser";
import {useRouter} from "next/navigation";
import {ThemeWrapper} from "@/components/theme-wrapper";
import * as React from "react";
import {useState} from "react";
import {RootMobileNav} from "@/components/root-mobile-nav";
import {RootNav} from "@/components/root-nav";

interface RootHeaderProps {
    bg?: string
}

export function RootHeader({bg}: RootHeaderProps) {
    const {data, status, update} = useSession();
    const [isOpen, setIsOpen] = useState(false);
    const {logOut} = useUser();
    const router = useRouter();
    const t = useTranslations('components.root-header');

    return (
        <header className={cn(bg ? `text-secondary ${bg} supports-[backdrop-filter]:${bg}/95 ` : "bg-[#242628] supports-[backdrop-filter]:bg-[#242628]/95 ", "sticky top-0 z-50 border-y border-foreground dark:border-background w-full border-b backdrop-blur ")}>
            <ThemeWrapper>
                <div className="container px-4 border-x border-foreground dark:border-background flex h-20 items-center">
                    <RootNav bg={bg}/>
                    <RootMobileNav/>
                    <div className="flex flex-1 items-center justify-between space-x-2 md:justify-end">
                        <nav className="flex items-center space-x-4">
                            <ModeToggle/>
                            {
                                status === 'authenticated' ? (<DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
                                    <DropdownMenuTrigger asChild>
                                        <Button variant="secondary"
                                                className={("h-20 w-20") + " border border-background rounded-full"}>
                                            <Avatar className={"w-16 h-16"}>
                                                <AvatarImage src={data?.user?.image ?? ""} alt="@shadcn"/>
                                                <AvatarFallback>HD</AvatarFallback>
                                            </Avatar>
                                        </Button>
                                    </DropdownMenuTrigger>
                                    <DropdownMenuContent className="w-56 mt-3" align="end" forceMount>
                                        <DropdownMenuLabel className="font-normal">
                                            <div className="flex flex-col space-y-2">
                                                <p className="text-sm font-medium leading-none">{data?.user?.name ?? ""}</p>
                                                <p className="text-xs leading-none text-muted-foreground">
                                                    {data?.user?.email ?? ""}
                                                </p>
                                            </div>
                                        </DropdownMenuLabel>
                                        <DropdownMenuSeparator/>
                                        <DropdownMenuGroup>
                                            <DropdownMenuItem onClick={() => {
                                                router.push(`/view/dashboard/coursewares`);
                                            }}>
                                                {t('my-courseware')}
                                                <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => {
                                                router.push(`/view/studio`);
                                            }}>
                                                {t('video-production')}
                                                <DropdownMenuShortcut>⌘S</DropdownMenuShortcut>
                                            </DropdownMenuItem>
                                            <DropdownMenuItem onClick={() => {
                                                router.push(`/view/account`);
                                            }}>
                                                {t('account-settings')}
                                                <DropdownMenuShortcut>⌘Z</DropdownMenuShortcut>
                                            </DropdownMenuItem>
                                        </DropdownMenuGroup>
                                        <DropdownMenuSeparator/>
                                        <DropdownMenuItem onClick={logOut}>
                                            {t('logout')}
                                            <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut>
                                        </DropdownMenuItem>
                                    </DropdownMenuContent>
                                </DropdownMenu>) : (
                                    <Link href={"/view/login"}
                                          className={cn(buttonVariants(), 'h-20 w-20 rounded-full')}>
                                        {t('login')}
                                    </Link>
                                )
                            }

                        </nav>
                    </div>
                </div>
            </ThemeWrapper>
        </header>
    )
}
