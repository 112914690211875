"use client";
import React from "react";
import {useTranslations} from 'next-intl'
import {Bubble} from '@halfwork.cn/nextjs'

interface SloganProps {
    config?: any;
}

export function Slogan({config}: SloganProps) {
    const t = useTranslations('home.slogan')
    return (
        <div className="bg-[#E3E4DD]">
            <div className="container relative border-x border-foreground dark:border-background/70 h-[20rem]">
                <div className="items-center flex flex-col justify-between mx-auto w-full h-full py-16">
                    <h1 className="text-3xl md:text-5xl 2xl:text-6xl items-center justify-center flex font-bold text-foreground dark:text-background/90 w-full h-full ">
                        {config?.slogan_title.length > 0 ? config.slogan_title : t('allInOneEditor')}
                    </h1>
                    <h2 className="text-base md:text-2xl items-center text-center justify-center text-foreground dark:text-background/80 flex w-full h-full  ">
                        {config?.slogan_desc.length > 0 ? config.slogan_desc : t('createExportInBrowser')}
                    </h2>
                </div>
            </div>
            <Bubble
                aiflow="qibltmg"
                previewMessage={{
                    message: t('aiHelper'),
                    autoShowDelay: 1000,
                    avatarUrl:
                        "https://img.halfdone.cn/eve3/default_avatar4.svg",
                }}
                theme={{
                    button: {backgroundColor: "hsl(var(--primary))"},
                    previewMessage: {
                        backgroundColor: "hsl(var(--primary))", textColor: "hsl(var(--primary-foreground))"
                    },
                    mobile: true
                }}
            />
        </div>
    );
}