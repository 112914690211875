"use client"

import * as React from "react"
import Link from "next/link"
import {usePathname} from "next/navigation"

import {cn} from "@/lib/utils"
import {Icons} from "@/components/icons"
import {
    NavigationMenu,
    NavigationMenuContent,
    NavigationMenuItem, NavigationMenuLink,
    NavigationMenuList,
    NavigationMenuTrigger
} from "@/registry/default/ui/navigation-menu";
import {useTranslations} from "next-intl";
import {buttonVariants} from "@/registry/new-york/ui/button";
import { useEditorConfig } from '@/hooks/use-editor'

interface RootNavProps {
    bg?: string
}

export function RootNav({bg}: RootNavProps) {
    const pathname = usePathname()
    const [config, setConfig] = useEditorConfig();
    const t = useTranslations('components.root-nav')
    return (
        <div className={cn("mr-4 hidden md:flex",bg?" text-foreground dark:text-secondary":" text-secondary dark:text-foreground")}>
            <Link href={"/view"}
                  className="mr-10 flex items-center space-x-4">
                <Icons.logo className="h-6 w-6"/>
                <h1 className="hidden font-bold text-4xl sm:inline-block">
                    HALF. <span
                    className="hidden bg-gradient-to-r from-ring to-foreground bg-clip-text text-transparent font-heading text-xl sm:inline-block"> VIEW</span>
                </h1>
            </Link>
            <nav className="flex items-center space-x-6 text-sm font-medium">
                <NavigationMenu>
                    <NavigationMenuList>
                        <NavigationMenuItem>
                            <NavigationMenuTrigger
                                className={cn(bg ?? "bg-[#242628]", 'bg-transparent font-bold text-md ')}>{t('features')}</NavigationMenuTrigger>
                            <NavigationMenuContent>
                                <ul className="grid gap-1 p-2 md:w-[80px] lg:w-[160px]  ">

                                    <ListItem className={'w-full'} href={`/view`}
                                              title={t('text-to-speech')}>
                                        <div className={'grid pt-2 gap-'}>
                                            <h6 className="text-sm text-muted-foreground">

                                            </h6>
                                        </div>
                                    </ListItem>
                                    <ListItem className={'w-full'} href={`/view`}
                                              title={t('ai-video-editing')}>
                                        <div className={'grid pt-2 gap-'}>
                                            <h6 className="text-sm text-muted-foreground">

                                            </h6>
                                        </div>
                                    </ListItem>
                                    <ListItem className={'w-full'} href={`/view`}
                                              title={t('subtitle-generator')}>
                                        <div className={'grid pt-2 gap-'}>
                                            <h6 className="text-sm text-muted-foreground">

                                            </h6>
                                        </div>
                                    </ListItem>
                                    <ListItem href={`/view`} title={t('ppt-to-course')}>
                                        <div className={'grid gap-1'}>
                                            <div className="text-sm  pt-2 text-muted-foreground">

                                            </div>
                                        </div>
                                    </ListItem>
                                </ul>
                            </NavigationMenuContent>
                        </NavigationMenuItem>
                    </NavigationMenuList>
                </NavigationMenu>
                <NavigationMenu>
                    <NavigationMenuList>
                        <NavigationMenuItem>
                            <NavigationMenuTrigger
                              className={cn(bg ?? " bg-[#242628]", 'bg-transparent font-bold text-md ')}>{t('customer-cases')}</NavigationMenuTrigger>
                            <NavigationMenuContent>
                                <ul className="grid gap-1 p-2 md:w-[180px] lg:w-[260px]  ">
                                    <ListItem className={'w-full'} onClick={(e) => {
                                        setConfig({...config, resFilter: 'template'})
                                    }} href={`/view/resource`}
                                              title={t('template')}>
                                        <div className={'grid pt-2 gap-'}>
                                            <h6 className="text-sm text-muted-foreground">
                                                丰富的学科模版
                                            </h6>
                                        </div>
                                    </ListItem>
                                    <ListItem className={'w-full'} onClick={(e) => {
                                        setConfig({...config, resFilter: 'lottie'})
                                    }} href={`/view/resource`}
                                              title={t('lottie')}>
                                        <div className={'grid pt-2 gap-'}>
                                            <h6 className="text-sm text-muted-foreground">
                                                582,000 + 免费的高级动画素材和源文件
                                            </h6>
                                        </div>
                                    </ListItem>
                                    <ListItem className={'w-full'} onClick={(e) => {
                                        setConfig({...config, resFilter: 'audio'})
                                    }} href={`/view/resource`}
                                              title={t('audio')}>
                                        <div className={'grid pt-2 gap-'}>
                                            <h6 className="text-sm text-muted-foreground">
                                                53,000 + 视频音效
                                            </h6>
                                        </div>
                                    </ListItem>
                                    <ListItem className={'w-full'} onClick={(e) => {
                                        setConfig({...config, resFilter: '3d'})
                                    }} href={`/view/resource`}
                                              title={t('3d')}>
                                        <div className={'grid pt-2 gap-'}>
                                            <h6 className="text-sm text-muted-foreground">
                                                46,,000 + 3D 素材和源文件
                                            </h6>
                                        </div>
                                    </ListItem>
                                    <ListItem onClick={(e) => {
                                        setConfig({...config, resFilter: 'illustration'})
                                    }} href={`/view/resource`} title={t('ill')}>
                                        <div className={'grid gap-1'}>
                                            <div className="text-sm  pt-2 text-muted-foreground">
                                                可 AI 编辑的不同风格的主题插图
                                            </div>
                                        </div>
                                    </ListItem>
                                    <ListItem onClick={(e) => {
                                        setConfig({...config, resFilter: 'icon'})
                                    }} href={`/view/resource`} title={t('svg')}>
                                        <div className={'grid gap-1'}>
                                            <div className="text-sm  pt-2 text-muted-foreground">
                                                100,000 + 矢量图, svg
                                            </div>
                                        </div>
                                    </ListItem>
                                </ul>
                            </NavigationMenuContent>
                        </NavigationMenuItem>
                    </NavigationMenuList>
                </NavigationMenu>
                <Link
                    href={"/view/price"}
                    className={cn(
                        "transition-colors font-bold text-md",
                        pathname?.startsWith("/view/price")
                            ? (!bg?"text-secondary dark:text-foreground":"text-foreground  dark:text-secondary")
                            : (!bg?"text-secondary/90 dark:text-foreground/90":"text-foreground/90 dark:text-secondary/90")
                    )}
                >
                    {t('pricing-plans')}
                </Link>
            </nav>
        </div>
    )
}

const ListItem = React.forwardRef<
    React.ElementRef<"a">,
    React.ComponentPropsWithoutRef<"a">
>(({className, title, children, ...props}, ref) => {
    return (
        <li>
            <NavigationMenuLink asChild>
                <a
                    ref={ref}
                    className={cn(
                        "block select-none space-y-0 rounded-md p-2 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
                        className
                    )}
                    {...props}
                >
                    <div className="text-md font-medium leading-none">{title}</div>
                    {children}
                </a>
            </NavigationMenuLink>
        </li>
    )
})
ListItem.displayName = "ListItem"
