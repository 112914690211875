"use client"
import React from "react";
import Image from "next/image";
import {cn} from "@/lib/utils";
import {Separator} from "@/registry/default/ui/separator";
import {useTranslations} from 'next-intl'
import {Button, buttonVariants} from "@/registry/default/ui/button";
import Link from "next/link";

interface IntroPageProps {
    config?: any;
}
export default function IntroPage({config}: IntroPageProps) {
    const t = useTranslations('home.intro')
    return (
        <div className={'bg-[#E3E4DD] text-foreground dark:text-background'}>
            <div className="container px-0 flex border-x border-muted-foreground h-full">
                <div className={'flex w-[50%] h-[450px] items-center justify-center'}>
                    <div className=" flex flex-col w-full gap-6 px-10">
                        <h2 className="text-3xl md:text-3xl font-medium   w-full h-full ">
                            {config?.intro_title.length > 0 ? config.intro_title :t('resource')}
                        </h2>
                        <h2 className="text-base md:text-xl w-full h-full  ">
                            {config?.intro_desc.length > 0 ? config.intro_desc :t('new')}
                        </h2>
                        <div className={'mt-2 flex'}>
                            <Link href={config?.workspaceId + "/"+config?.intro_link} className={cn(buttonVariants({ variant: 'default'}), 'border-background border rounded-full h-14')} > {`${t('more')} ->`}</Link>
                        </div>
                    </div>
                </div>
                <Separator orientation="vertical" className="h-100"/>
                <div className=" flex h-[450px] w-[50%] bg-[#828c97] items-center justify-center overflow-hidden ">
                    <div className="relative h-full w-full">
                        <div className={'absolute h-full w-full'}>
                            <Image
                                alt={'intro-two'}
                                src={'/images/intro-two.svg'}
                                width={250}
                                height={150}
                                className={cn(
                                    "h-full w-full object-cover transition-all"
                                )}
                            />
                        </div>
                        <div className={'absolute h-full w-full'}>
                            <Image
                                alt={'intro-one'}
                                src={'/images/intro-one.svg'}
                                width={250}
                                height={150}
                                className={cn(
                                    "h-full w-full object-cover transition-all"
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
