"use client";
import React from "react";
import {useTranslations} from 'next-intl'
import {cn} from "@/lib/utils";
import Image from "next/image";

interface FeatureProps {
    config?: any;
}

export function Feature({config}: FeatureProps) {
    const t = useTranslations('home.feature')
    return (
        <div className="bg-[#E3E4DD]">
            <div className="container px-0 border-x h-[24rem] border-foreground dark:border-background text-foreground dark:text-background/90">
                <div className="items-start md:justify-center w-full h-full md:grid lg:grid-cols-4 xl:grid-cols-4">
                    <div className="p-6  bg-white dark:bg-card/20 rounded-3xl md:col-span-1 md:flex-col items-start space-y-8 lg:col-span-1 border border-muted-foreground h-full">
                        <Image
                            alt={'one'}
                            src={'/images/one.svg'}
                            width={350}
                            height={100}
                            className={cn(
                                'h-14 w-14 object-cover transition-all',
                            )}
                        />
                        <h1 className="text-2xl items-start flex font-bold  w-full ">
                            {config?.feature1_title.length > 0 ? config.feature1_title : t('rightNow')}
                        </h1>
                        <h2 className="text-base md:text-xl items-center justify-center flex w-full  ">
                            {config?.feature1_desc.length > 0 ? config.feature1_desc : t('editor')}
                        </h2>
                    </div>
                    <div
                        className="p-6  bg-white dark:bg-card/20 rounded-3xl  md:col-span-1 md:flex-col items-start space-y-8 lg:col-span-1 border border-muted-foreground h-full">
                        <Image
                            alt={'two'}
                            src={'/images/two.svg'}
                            width={350}
                            height={350}
                            className={cn(
                                'h-14 w-14  object-cover transition-all',
                            )}
                        />
                        <h1 className="text-2xl items-start flex font-bold   w-full ">
                            {config?.feature2_title.length > 0 ? config.feature2_title : t('view')}
                        </h1>
                        <h2 className="text-base md:text-xl items-center justify-center flex w-full ">
                            {config?.feature2_desc.length > 0 ? config.feature2_desc : t('drag')}
                        </h2>
                    </div>
                    <div
                        className="p-6  bg-white dark:bg-card/20  rounded-3xl  md:col-span-1 md:flex-col items-start space-y-8 lg:col-span-1 border border-muted-foreground h-full">
                        <Image
                            alt={'three'}
                            src={'/images/three.svg'}
                            width={350}
                            height={150}
                            className={cn(
                                'h-14 w-14  object-cover transition-all',
                            )}
                        />
                        <h1 className="text-2xl items-start flex font-bold   w-full">
                            {config?.feature3_title.length > 0 ? config.feature3_title : t('subject')}
                        </h1>
                        <h2 className="text-base md:text-xl items-center justify-center flex w-full ">
                            {config?.feature3_desc.length > 0 ? config.feature3_desc : t('teach')}
                        </h2>
                    </div>
                    <div
                        className="p-6  bg-white dark:bg-card/20 rounded-3xl  md:col-span-1 md:flex-col items-start space-y-8 lg:col-span-1 border border-muted-foreground h-full">
                        <Image
                            alt={'four'}
                            src={'/images/four.svg'}
                            width={350}
                            height={350}
                            className={cn(
                                'h-14 w-14 object-cover transition-all',
                            )}
                        />
                        <h1 className="text-2xl items-start flex font-bold   w-full">
                            {config?.feature4_title.length > 0 ? config.feature4_title : t('language')}
                        </h1>
                        <h2 className="text-base md:text-xl items-center justify-center flex w-full ">
                            {config?.feature4_desc.length > 0 ? config.feature4_desc : t('aiCreator')}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    );
}