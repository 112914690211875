"use client";
import React from "react";
import {Separator} from "@/registry/default/ui/separator";
import {Button} from "@/registry/default/ui/button";
import {Combine, FileCode} from "lucide-react";
import {useRouter} from "next/navigation";
import {cn} from "@/lib/utils";
import {useTranslations} from "next-intl";

interface EnrolmentProps {
    config?: any;
    bg?: string;
}

export function EnrolmentSection({config, bg}: EnrolmentProps) {
    const router = useRouter();
    const t = useTranslations('home.enrolment')
    return (
        <div className={cn(bg ? bg:`bg-[#FF6102]`)}>
            <div className={'border-b border-foreground dark:border-background'}>
                <div className="container border-x border-foreground dark:border-background h-full py-8">
                    <h2 className="text-xl items-center justify-center flex w-full h-full dark:text-neutral-200">
                        {t('ready')}
                    </h2>
                </div>
            </div>
            <div className={'border-b border-foreground dark:border-background'}>
                <div className="container border-x border-foreground dark:border-background w-full h-full px-0">
                    <Button variant={'secondary'}
                            onClick={() => {
                                router.push(`/${config.workspaceId}/studio`)
                            }}
                            className="py-14 hover:rounded-none bg-[#242628] hover:bg-[#242628]/60 transition-all duration-500 rounded-full border-x border-foreground dark:border-background px-0 text-3xl md:text-5xl items-center justify-center flex font-medium text-secondary dark:text-foreground w-full h-full ">
                        {t('start')}
                    </Button>
                </div>
            </div>
            <div className={'border-b border-foreground dark:border-background'}>
                <div className="container flex justify-between border-x border-foreground dark:border-background h-full px-0">
                    <Button variant={'ghost'}
                            className="items-center hover:bg-background/30 gap-4 flex transition-all duration-500 hover:rounded-full rounded-none hover:border-x justify-center mx-auto w-full h-full py-10">
                        <FileCode className="mr-2 h-14 w-14 stroke-1"/>
                        <div className={'flex flex-col gap-1'}>
                            <h3 className="text-2xl  items-center justify-center flex font-medium dark:text-white h-full ">
                                {t('one')}
                            </h3>
                            <h6 className={'underline items-start justify-start text-md underline-offset-4 ml-0'}>
                                {t('doc')}
                            </h6>
                        </div>
                    </Button>
                    <Separator orientation="vertical" className="h-100 bg-foreground dark:bg-background"/>
                    <Button variant={'ghost'}
                            className="items-center gap-4 flex transition-all hover:bg-background/30 duration-500 hover:rounded-full rounded-none hover:border-x justify-center mx-auto w-full h-full py-10">
                        <Combine className="mr-2 h-14 w-12 stroke-1"/>
                        <div className={'flex flex-col  gap-1'}>
                            <h3 className="text-2xl  items-center justify-center flex font-medium dark:text-white h-full ">
                                {t('demo')}
                            </h3>
                            <h6 className={'underline items-start justify-start text-md underline-offset-4'}>
                                {t('contact')}
                            </h6>
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
}