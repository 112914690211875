"use client";
import React from "react";
import { useTranslations } from 'next-intl'
interface EverySectionProps {
    config?: any;
}
export function EverySection({config}:EverySectionProps) {
    const t = useTranslations('home.every')
    return (
        <div className="bg-[#E3E4DD]">
            <div className="container relative border-x h-[20rem] border-foreground dark:border-background text-foreground dark:text-background">
                <div className="items-center flex flex-col justify-between mx-auto w-full h-full py-16">
                    <h1 className="text-3xl md:text-5xl 2xl:text-6xl items-center justify-center flex font-bold w-full h-full ">
                        {config?.every_title.length > 0 ? config.every_title :t('everyoneInvolved')}
                    </h1>
                    <h2 className="text-base md:text-2xl items-center justify-center flex w-full h-full">
                        {config?.every_desc.length > 0 ? config.every_desc :t('dynamicDesignSystem')}
                    </h2>
                </div>
            </div>
        </div>
    );
}